<template>
  <div class="row-history">
    <!-- <component :is="report"></component> -->
    <a id="simulation" href=""></a>
    <el-container class="row-con">
      <el-header class="m-filter">
        <div class="wp">
          <div class="filter">
            <el-form ref="filter" class="m-filterbox">
              <div class="right">
                <el-button @click="querychxun" class="btn btn-search" type="primary" icon="el-icon-search">查询
                </el-button>
                <el-button class="btn btn-reset" native-type="reset" @click="resetClick">
                  <span class="icon icon-reset"></span><span>重置</span>
                </el-button>
              </div>
              <div class="left">
                <el-form-item class="item g-date">
                  <el-col :span="14" class="date">
                    <el-date-picker value-format="timestamp" type="date" v-model="startDate" placeholder="选择日期" style="width: 100%;">
                    </el-date-picker>
                  </el-col>
                </el-form-item>
                <div class="item">至</div>
                <el-form-item class="item g-date">
                  <el-col :span="14" class="date">
                    <el-date-picker value-format="timestamp" type="date" prefix-icon="" v-model="endDate" placeholder="选择日期" style="width: 100%;">
                    </el-date-picker>
                  </el-col>
                </el-form-item>

                <el-form-item class="item picker picker-device">
                  <el-select v-model="deviceKey" filterable multiple reserve-keyword placeholder="请选择设备">
                    <el-option v-for="item in deviceList" :key="item.key" :label="item.name" :value="item.key">
                    </el-option>
                  </el-select>
                </el-form-item>

              </div>
            </el-form>
          </div>
        </div>
      </el-header>
      <el-main class="m-table-main">
        <div id="table-content" style="height:85%">
          <el-table :data="tableData" height="100%" style="width: 100%" ref="table" row-key="id" @selection-change="selectionChange" :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
            <el-table-column type="selection" align='center' fixed></el-table-column>
            <el-table-column prop="deviceName" label="设备号" min-width='120px' align='center' show-overflow-tooltip></el-table-column>
            <el-table-column prop="pileDescribe" label="桩号" min-width='120px' align='center' show-overflow-tooltip></el-table-column>
            <el-table-column prop="beginTime" label="开始时间" min-width='120px' align='center' show-overflow-tooltip>
              <template slot-scope="scope">
                {{ scope.row.beginTime|datafilters }}
              </template>
            </el-table-column>
            <el-table-column prop="endTime" label="结束时间" min-width='120px' align='center' show-overflow-tooltip>
              <template slot-scope="scope">
                {{ scope.row.endTime|datafilters }}
              </template>
            </el-table-column>
            <el-table-column prop="pileTime" label="成桩时间(min)" min-width='120px' align='center' show-overflow-tooltip>
              <template slot-scope="scope">
                {{ scope.row.pileTime|minfilters }}
              </template>
            </el-table-column>
            <el-table-column prop="depth" label="桩长(m)" min-width='120px' align='center' show-overflow-tooltip>
              <template slot-scope="scope">
                {{ scope.row.depth|numfilters }}
              </template>
            </el-table-column>
            <el-table-column prop="cumulativePulp" label="累计浆量(L)" min-width='120px' align='center' show-overflow-tooltip>
              <template slot-scope="scope">
                {{ scope.row.cumulativePulp|numfilters }}
              </template>
            </el-table-column>
            <el-table-column prop="cumulativeAsh" label="累计灰量(kg)" min-width='120px' align='center' show-overflow-tooltip>
              <template slot-scope="scope">
                {{ scope.row.cumulativeAsh|numfilters }}
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="dataStatistics">
          <div class="total">
            <p>总时间: <span>{{pileDataTime}}</span> </p>
          </div>
          <div class="total">
            <p>总根数: <span>{{piltTotalNum}}根</span> </p>
          </div>
          <div class="total">
            <p>总桩长: <span>{{pileTotalDeep|numfilters}}m</span> </p>
          </div>
          <div class="total">
            <p>总浆量: <span>{{pileTotalPulp|numfilters}}L</span> </p>
          </div>
          <div class="total">
            <p>总灰量: <span>{{pileTotalAsh|numfilters}}Kg</span> </p>
          </div>
        </div>
      </el-main>
    </el-container>
    <el-footer class="m-selectAll">
      <div class="right">
        <el-pagination class="pages" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[30, 50, 80,100]" :page-size="pageSize" layout="slot,prev, pager, next" :total="total">
          <!-- slot部分，跳转首页 -->
          <button class="lastPage" @click="toFirstPage">
            <i class="el-icon-d-arrow-left"></i>
          </button>
        </el-pagination>
        <el-pagination class="pages" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[30, 50, 80,100]" :page-size="pageSize" layout="slot, jumper, sizes,total" :total="total">
          <!-- slot部分，跳转末页 -->
          <button class="lastPage" @click="toLastPage">
            <i class="el-icon-d-arrow-right"></i>
          </button>
        </el-pagination>
      </div>
    </el-footer>
  </div>
</template>
<script>
import report from '../report/report.vue'
import { http, show } from '../../main'
import moment from "moment";
import { historyList, queryDevices } from "@/api/api"
import gTabs from "@/components/gTabs";
import * as echarts from "echarts";
export default {
  components: {
    gTabs
  },
  // props: ['tableData', 'device', 'deviceList', 'pageSize', 'total', 'currentPage', 'handleSizeChange', 'toLastPage', 'toFirstPage', 'handleCurrentChange'],
  data() {
    return {
      value1: '',
      report: 'report',

      deviceList: [],
      tableData: [],
      pageSize: 30,
      total: 0,
      currentPage: 0,
      totalChecked: false,
      startDate: '',
      endDate: '',
      Pilenumber: "",
      deviceKey: '',
      pileDataTime: '',
      piltTotalNum: 0,
      pileTotalDeep: 0,
      pileTotalPulp: 0,
      pileTotalAsh: 0,

      inquire: [],
      totalNum: 0,

    }
  },
  filters: {
    datafilters(val) {
      return moment(val * 1000).format('YYYY-MM-DD HH:mm:ss')
    },
    numfilters(val, deg) {
      if (deg == undefined) {
        deg = 2
      }
      if (isNaN(val)) {
        //val 不是数字
        return val
      } else {
        //val是数字
        return parseFloat(val).toFixed(deg)
      }
    },
    minfilters(val) {
      return parseInt(val / 60)
    },
    num3filters(val) {
      return Math.abs(val.toFixed(1))
    },
    num4filters(val) {
      return val > 0 ? '下钻' : '提钻'
    }
  },
  watch: {

  },
  mounted() {
    let data = new Date()
    // new Date(data.toLocaleDateString()).getTime() 将日期转化为时间戳
    this.startDate = new Date(data.toLocaleDateString()).getTime()
    this.endDate = new Date(data.toLocaleDateString()).getTime() + 86399000
    queryDevices(9999, 1, 'GFZ').then((res) => {
      this.deviceList = res.result.records;
    });
    historyList('GFZ', 0, this.deviceKey, this.pageSize, this.Pilenumber, this.startDate, this.endDate).then((res) => {
      this.tableData = res.content;
      this.total = res.totalElements;
    });
    this.totalstatistical()
  },
  methods: {
    totalstatistical() {
      historyList('GFZ', 0, '', 100, '', this.startDate, this.endDate).then((res) => {
        this.piltTotalNum = res.totalElements
        if (res.content.length != 0) {
          let time = res.content[0].endTime - res.content[res.content.length - 1].beginTime
          let hour = Math.floor(time / 3600) >= 10 ? Math.floor(time / 3600) : "0" + (Math.floor(time / 3600))
          let minutes = Math.floor((time - hour * 3600) / 60) >= 10 ? Math.floor((time - hour * 3600) / 60) : "0" + (Math.floor((time - hour * 3600) / 60))
          let seconds = Math.floor(time - hour * 3600 - minutes * 60) >= 10 ? Math.floor(time - hour * 3600 - minutes * 60) : "0" + (Math.floor(time - hour * 3600 - minutes * 60))
          this.pileDataTime = hour + '小时' + minutes + '分钟' + seconds + '秒'
          for (let i = 0; i < res.content.length; i++) {
            this.pileTotalDeep += res.content[i].depth
            this.pileTotalPulp += res.content[i].cumulativePulp
            this.pileTotalAsh += res.content[i].cumulativeAsh
          }
        } else {
          this.pileDataTime = 0 + '小时' + 0 + '分钟' + 0 + '秒'
        }
      });
    },
    // 设置table表格的宽度
    tableWidth(index) {
      if (index == 0 || index == 5 || index == 6 || index == 7) {
        return 115
      }
      if (index == 8 || index == 9) {
        return 140
      }
    },
    //表格导出的封装
    tableToExcel(tableid, sheetName) {
      let uri = 'data:application/vnd.ms-excel;base64,';
      let template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel"' +
        'xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>'
        + '<x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets>'
        + '</x:ExcelWorkbook></xml><![endif]-->' +
        ' <style type="text/css">' +
        'table td {' +
        'height: 25px;' +
        'font-family: 宋体;' +
        'font-size: 13px;' +
        'text-align: left;' +
        'border: 0.5px solid #000000;' +
        ' }' +
        'table .tHead {' +
        'font-size: 20px;' +
        'text-align:center;' +
        'font-weight: bold;' +
        'height: 40px;' +
        ' }' +
        'table tfoot tr{' +
        'height: 25px;' +
        ' }' +
        'table tbody td{' +
        'text-align: center;' +
        'height: 20px;' +
        ' }' +
        '</style>' +
        '</head><body ><table class="excelTable">{table}</table></body></html>';
      if (!tableid.nodeType) tableid = document.getElementById(tableid);
      let ctx;
      if (tableid) {
        ctx = { worksheet: sheetName || 'Worksheet', table: tableid.innerHTML };
        document.getElementById('simulation').href = uri + this.base64(this.format(template, ctx));
        document.getElementById('simulation').download = sheetName + ".xls";//自定义文件名
        document.getElementById('simulation').click();
      }
    },
    base64(s) {
      return window.btoa(unescape(encodeURIComponent(s)));
    },
    format(s, c) {
      return s.replace(/{(\w+)}/g,
        function (m, p) {
          return c[p];
        });
    },
    // 设备查询
    querychxun() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      //选择设备查询
      //将修改的key值传到主页面
      //在主页面改为传过来的值
      //在主页面写一个方法接受传过来的值
      if (this.endDate != '') {
        this.endDate = this.endDate + 86399000
      } else {
        this.endDate = ''
      }
      if (this.deviceKey.length > 0) {
        var device = this.deviceKey.join()
      } else {
        device = ''
      }
      historyList('GFZ', 0, device, this.pageSize, this.Pilenumber, this.startDate, this.endDate).then((res) => {
        this.tableData = res.content;
        this.total = res.totalElements;
        this.piltTotalNum = res.totalElements
        if (res.content.length != 0) {
          let time = res.content[0].endTime - res.content[res.content.length - 1].beginTime
          let hour = Math.floor(time / 3600) >= 10 ? Math.floor(time / 3600) : "0" + (Math.floor(time / 3600))
          let minutes = Math.floor((time - hour * 3600) / 60) >= 10 ? Math.floor((time - hour * 3600) / 60) : "0" + (Math.floor((time - hour * 3600) / 60))
          let seconds = Math.floor(time - hour * 3600 - minutes * 60) >= 10 ? Math.floor(time - hour * 3600 - minutes * 60) : "0" + (Math.floor(time - hour * 3600 - minutes * 60))
          this.pileDataTime = hour + '小时' + minutes + '分钟' + seconds + '秒'
          for (let i = 0; i < res.content.length; i++) {
            this.pileTotalDeep += res.content[i].depth
            this.pileTotalPulp += res.content[i].cumulativePulp
            this.pileTotalAsh += res.content[i].cumulativeAsh
          }
        } else {
          this.pileDataTime = 0 + '小时' + 0 + '分钟' + 0 + '秒'
        }

        loading.close();
      });
      setTimeout(() => {
        loading.close();
      }, 6000);
    },
    // 重置按钮
    resetClick() {
      this.deviceKey = ""
      this.startDate = ''
      this.endDate = ''
      this.number = ""
    },
    //历史列表导出选择框 选中发生变化
    selectionChange(e) {
      if (e.length == this.tableData.length) {
        this.totalChecked = true;
      } else if (this.totalChecked) {
        this.totalChecked = false;
      }
    },
    handleSizeChange(val) {
      this.pageSize = val
      historyList('GFZ', 0, this.deviceKey, this.pageSize, this.Pilenumber, this.startDate, this.endDate).then((res) => {
        this.tableData = res.content;
        this.total = res.totalElements;
      });
    },
    handleCurrentChange(val) {
      this.currentPage = val
      historyList('GFZ', this.currentPage - 1, this.deviceKey, this.pageSize, this.Pilenumber, this.startDate, this.endDate).then((res) => {
        this.tableData = res.content;
        this.total = res.totalElements;
      });
    },
    toFirstPage() {
      this.handleCurrentChange(1)
    },
    toLastPage() {
      this.handleCurrentChange(Math.ceil(this.total / this.pageSize))
    },
  }
}
</script>
<style scoped>
/* 底部统计 */
.dataStatistics {
  width: 100%;
  height: 15%;
  /* line-height: 10%; */
  display: flex;
  justify-content: space-between;
}
.dataStatistics .total {
  font-size: 28px;
  font-weight: normal;
  line-height: 100px;
  color: #101010;
}
.dataStatistics .total span {
  font-size: 18px;
}
/* 底部统计 */
.m-filter .item.picker {
  width: 30.25rem;
}

.m-tab-echartTable {
  position: relative;
}
.reportEchart {
  position: absolute;
  top: 0;
  left: -1000px;
  width: 800px;
  height: 800px;
}
.echarts_report {
  width: 100px;
  height: 40px;
  line-height: 40px;
  margin-top: 10px;
  border-radius: 20px;
  color: #fff;
  text-align: center;
  background: #409eff;
  float: left;
  margin-right: 10px;
  cursor: pointer;
}
.mapDiv {
  height: 100%;
  padding: 0.75rem 1.25rem;
}
.map {
  height: 100%;
}
.backplayBottom {
  background: #fff;
  border-radius: 10px;
  margin-top: 5px;
  height: 4rem;
  display: flex;
  justify-content: space-between;
}
.iconBack {
  color: #000;
  /* color: #409eff; */
  font-size: 26px;
  font-weight: bold;
  line-height: 4rem;
}
.progress_bar {
  width: 75%;
  margin-top: 25px;
}
.selectspeed {
  margin-top: 15px;
  width: 120px;
}
/* >>> .el-input--small .el-input__inner {
  color: #409eff;
} */

.play_back {
  height: 50%;
  display: flex;
  justify-content: space-between;
}
.m-statusinfo {
  background: #fff;
}

.play_col {
  background: #fff;
  margin: 5px;
  border-radius: 10px;
}
.backEchart {
  height: 18.3rem;
}
.m-datab1 {
  padding-top: 0.75rem;
}
.m-datab1 .top {
  margin-bottom: 0;
}

.picbox .data {
  bottom: 96%;
}
.picbox .databar {
  bottom: 10px;
}
/* 回放样式end */

.ul-detail li {
  width: 33%;
}
.el-checkbox {
  width: 20%;
}
.el-button--primary {
  margin-left: 25px;
}
.el-button--primary {
  margin-left: 25px;
  margin-right: 5px;
}
.m-filter .filter .btn {
  margin-left: 2.5rem;
}
.ul-detail .tit {
  min-width: 5em;
}
.ul-detail .s2 .tit {
  min-width: 8em;
}
.ul-detail .s3 .tit,
.ul-detail .s4 .tit {
  min-width: 10em;
}
/* 历史记录 详情 详细数据下的padding值 */
.ul-detail li {
  padding: 0 1.3rem 0 0;
}
.ul-detail li:first-child {
  padding-left: 1.6rem;
}
.m-filter .filter .btn[data-v-90e2c994] {
  margin-left: 20px;
}
</style>

